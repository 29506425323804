<template>
  <div class="download">
    <div class="download-wrap">
      <div class="h5-wrap"
           v-if="weixinFlag">
        <img :src="tipImg"
             alt=""
             class="tip-img">
      </div>
      <div class="download-bg">
        <img :src="bg"
             alt=""
             class="download-img">
        <img :src="logo"
             alt=""
             class="logo-img">
        <img :src="phone"
             alt=""
             class="phone-img">
      </div>：

      <div class="download-content flex">
        <div class="version">村口壹站 V{{version}}</div>
        <div class="flex version-wrap">
          <div class="version-l">
            <div class="code-desc">请扫二维码下载体验</div>
            <img :src="tempcode"
                 alt=""
                 class="code-img">
          </div>
          <div class="version-r">
            <img :src="buttonImg"
                 alt=""
                 class="button-img"
                 @click="downloadBtn('android')">
            <img :src="buttonIOSImg"
                 alt=""
                 class="button-img button-ios"
                 @click="downloadBtn('ios')">
          </div>
        </div>

        <div class="copyright">{{companyName}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {COMPANY_NAME} from '@/utils/constantNum'
import { getVersionInfo } from '@/utils/api'
import common from '@/utils/common'
import {uwStatisticAction} from "../../utils/util";
export default {
  data () {
    return {
      tipImg: require('@/assets/img/download/tip.png'),
      buttonImg: require('@/assets/img/download/button.png'),
      buttonIOSImg: require('@/assets/img/download/button_ios.png'),
      download_bg: require('@/assets/img/download/download_bg.png'),
      bg: require('@/assets/img/download/bg.png'),
      logo: require('@/assets/img/download/logo.png'),
      phone: require('@/assets/img/download/phone.png'),
      code: require('@/assets/img/download/code.png'),
      sitcode: require('@/assets/img/download/sitcode.png'),
      weixinFlag: false,
      version: null,
      tempcode: '',
      androidUrl: null,
      iosUrl: null,
      companyName:COMPANY_NAME,
    }
  },
  mounted () {
    this.weixinFlag = this.isWeiXin()
    this.getVersion('ios')
    this.getVersion('android')
    if (location.hostname.indexOf('h5-test') >= 0 || location.hostname.indexOf('localhost') >= 0) {
      this.tempcode = this.sitcode
    } else {
      this.tempcode = this.code
    }
    uwStatisticAction('/download','app下载')
  },
  methods: {
    isWeiXin () {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    getVersion (platform) {
      getVersionInfo({
        platform
      }).then(res => {
        if (res.code == 200) {
          if (res.data && res.data.businessCode == 200) {
            if (platform == 'android') {
              if(res.data.data.version.indexOf(';')> -1){
                this.version = res.data.data.version.split(';')[0]
              }else{
                this.version = res.data.data.version
              }
              this.androidUrl = `${res.data.data.url}?version=${res.data.data.version}`
            } else {
              this.iosUrl = `${res.data.data.url}?version=${res.data.data.version}`
            }
          }
        }
      })
    },
    downloadBtn (str) {
      window.location.href = str == 'android' ? this.androidUrl : this.iosUrl
    }
  }
}
</script>

<style lang="less" scoped>
.download {
  position: relative;
  height: 100%;
  .download-img {
    width: 100%;
    height: 100vh;
    min-height: 11.8rem;
  }
  .download-bg {
    position: relative;
    .logo-img {
      position: absolute;
      width: 2rem;
      height: 0.52rem;
      z-index: 10;
      left: 0.4rem;
      top: 0.7rem;
    }
    .phone-img {
      position: absolute;
      width: 4.13rem;
      // height: 851px;
      z-index: 10;
      left: 50%;
      top: 1.61rem;
      transform: translateX(-50%);
    }
  }
  .code-img {
    width: 2.16rem;
    height: 2.16rem;
    padding: 0.05rem;
    border: 1px solid #eee;
    margin-top: 0.1rem;
  }
  .button-img {
    width: 3.12rem;
    margin-top: 0.9rem;
    display: block;
  }
  .button-ios {
    margin-top: 0.38rem;
  }
  .download-wrap {
    position: relative;
    .download-content {
      z-index: 10;
      width: 100%;
      background: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      flex-direction: column;
      align-items: center;
    }
    .version {
      color: #3f7c53;
      font-size: 0.34rem;
      margin: 0.31rem 0.08rem 0;
    }
    .version-wrap {
      margin: 0 0.8rem;
    }
    .version-l {
      width: 2.5rem;
      flex: 0 0 2.5rem;
    }
    .version-r {
    }
    .code-desc {
      color: #272829;
      font-size: 0.24rem;
      margin-top: 0.41rem;
    }
    .copyright {
      font-size: 0.2rem;
      color: #999;
      margin: 0.5rem 0 0.33rem;
    }
  }

  .h5-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 13.4rem;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    text-align: right;
    z-index: 20;
    .tip-img {
      width: 2.5rem;
      margin-right: 0.66rem;
      margin-top: 0.38rem;
    }
  }
  .tip-img {
    width: 2.5rem;
    margin-right: 0.66rem;
    margin-top: 0.38rem;
  }
  .download-btn {
    width: 3rem;
    height: 0.8rem;
    margin-top: 0.38rem;
    line-height: 0.8rem;
    background: #3f7c53;
    color: #fff;
    font-size: 0.32rem;
    border-radius: 0.4rem;
  }
}
</style>